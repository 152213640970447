import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import { reposicaoStore, alunoStore } from '@/store';
import EntidadeLinkComponent from '@/components/EntidadeLinkComponent.vue';
import ClickableCard from '@/components/ClickableCard.vue';
import TextExpandableComponent from '@/components/TextExpandableComponent.vue';
import ShowPopup from '@/components/ShowPopup.vue';
import OcorrenciaComponent from '@/components/OcorrenciaComponent.vue';
import PedagogicoAlunoComponent from '@/components/PedagogicoAlunoComponent.vue';
import PeriodSelectorComponent from '@/components/PeriodSelectorComponent.vue';
import PedagogicoMarcarReposicaoComponent from '@/components/PedagogicoMarcarReposicaoComponent.vue';
let Caixa = class Caixa extends Vue {
    constructor() {
        super(...arguments);
        this.loading = true;
        this.alunoSelected = null;
        this.atribuidasVoceFilters = [0];
        this.criadasPorVoceFilters = [0];
        this.header = [
            { text: 'Criado em', value: 'created_at' },
            { text: 'Data', value: 'data_hora' },
            { text: 'Aluno', value: 'aluno' },
            { text: 'Professor da turma', value: 'professor_turma' },
            { text: 'Professor que irá lecionar', value: 'professor' },
            { text: 'Contato', value: 'aluno_contatos' },
            { text: 'Descrição', value: 'descricao' },
            { text: 'Atividade', value: 'atividade' },
            { text: 'Status', value: 'status' },
            { text: '', value: 'actions' },
        ];
        this.items = [];
        this.currentId = null;
        this.tarefaRealizadaDialog = false;
        this.tarefaCanceladaDialog = false;
        this.currentItemObservacao = '';
        this.exibirEncerradas = false;
        this.atribuidasVoceItems = [];
        this.atribuidasVoceSearch = '';
        this.criadasPorVoceItems = [];
        this.criadasPorVoceSearch = '';
        this.selectedAluno = null;
        this.aluno = null;
        this.selectPeriodDate = [];
        this.dialogMarcarReposicao = false;
        this.turmaAlunoAtividade = [];
        this.aluno_id = 0;
        this.turma_id = 0;
        this.turma_aluno_id = 0;
        this.turma_aluno_atividade_id = 0;
        this.atividade_id = 0;
        this.qtdAlunoDiaSemanaResponse = {};
        this.propertyToShowDetails = [
            // @ts-ignore
            ...this.diasDaSemana(),
        ];
    }
    aulaExtraStatusOptions(id) {
        switch (id) {
            case 1:
                return 'Aguardando';
            case 2:
                return 'Aprovado';
            case 3:
                return 'Reprovado';
            case 4:
                return 'Agendado';
            case 5:
                return 'Desmarcado pelo aluno';
            case 6:
                return 'Falta';
            case 7:
                return 'Presença';
        }
    }
    get qtdAlunoPorDiaSemana() {
        let diasSemanaQuantidade = {};
        // @ts-ignore
        for (const dia of this.diasDaSemana()) {
            const hasItem = this.qtdAlunoDiaSemanaResponse[dia];
            if (!hasItem) {
                diasSemanaQuantidade = { [dia]: 0, ...diasSemanaQuantidade };
            }
            else {
                diasSemanaQuantidade = {
                    [dia]: hasItem.length,
                    ...diasSemanaQuantidade,
                };
            }
        }
        return diasSemanaQuantidade;
    }
    get itemsFormatted() {
        const newItemsFormatted = [];
        for (const diaDaSemana of this.propertyToShowDetails) {
            if (this.qtdAlunoDiaSemanaResponse[diaDaSemana]) {
                for (const aluno of this.qtdAlunoDiaSemanaResponse[diaDaSemana]) {
                    newItemsFormatted.push(aluno);
                }
            }
        }
        return newItemsFormatted;
    }
    async showAluno(item) {
        this.selectedAluno = null;
        this.selectedAluno = item;
        const form = this.$refs.showAlunoReforco;
        this.aluno = await alunoStore.getAluno(parseInt(this.selectedAluno.aluno_id, 0));
        form.show();
    }
    async mounted() {
        await this.atualizarDados();
    }
    async atualizarDados() {
        this.loading = true;
        this.qtdAlunoDiaSemanaResponse =
            await reposicaoStore.getReforcoAlunosAprovados();
        this.loading = false;
    }
};
Caixa = __decorate([
    Component({
        components: {
            ClickableCard,
            EntidadeLinkComponent,
            TextExpandableComponent,
            ShowPopup,
            OcorrenciaComponent,
            PedagogicoAlunoComponent,
            PeriodSelectorComponent,
            PedagogicoMarcarReposicaoComponent,
        },
    })
], Caixa);
export default Caixa;
