import { __decorate } from "tslib";
import { Vue, Component, Prop } from 'vue-property-decorator';
import { turmaAlunoStore } from '@/utils/store-accessor';
import OpenNewTabComponent from '@/components/OpenNewTabComponent.vue';
let TurmaEvadirAlunoComponent = class TurmaEvadirAlunoComponent extends Vue {
    constructor() {
        super(...arguments);
        this.loading = false;
        this.turmaCodigoReferencia = null;
        this.turmaAlunoDetalhamento = null;
    }
    get allow() {
        // @ts-ignore
        return this.turmaAlunoDetalhamento ? this.turmaAlunoDetalhamento.quantidade_faltas_consecutivas >= 8 : false;
    }
    async confirmar() {
        this.loading = true;
        await turmaAlunoStore.evadir(this.propTurmaAlunoId);
        this.loading = false;
        this.$emit('on-cancel');
    }
    onCancel() {
        this.$emit('on-cancel');
    }
    async mounted() {
        this.loading = true;
        const dados = await turmaAlunoStore.getDetalhamentoTurmaAluno(this.propTurmaAlunoId);
        // @ts-ignore
        if (dados.length > 0) {
            // @ts-ignore
            this.turmaAlunoDetalhamento = dados[0];
        }
        this.loading = false;
    }
};
__decorate([
    Prop({ type: Number, default: null })
], TurmaEvadirAlunoComponent.prototype, "propTurmaAlunoId", void 0);
TurmaEvadirAlunoComponent = __decorate([
    Component({
        components: {
            OpenNewTabComponent,
        },
    })
], TurmaEvadirAlunoComponent);
export default TurmaEvadirAlunoComponent;
