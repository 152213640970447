import { __decorate } from "tslib";
import { Vue, Component, Prop } from 'vue-property-decorator';
import _ from 'lodash';
import { turmaAlunoAtividadeStore } from '@/utils/store-accessor';
let PedagogicoMarcarReposicaoComponent = class PedagogicoMarcarReposicaoComponent extends Vue {
    constructor() {
        super(...arguments);
        this.headers = [
            { text: 'Atividade', value: 'atividade_descricao' },
            { text: 'Executada em', value: 'atividade_data_executada' },
        ];
        this.motivoCancelamento = '';
    }
    onCancel() {
        this.$emit('on-cancel');
    }
    async reporAula() {
        const ids = _.map(this.propItems, 'turma_aluno_atividade_id');
        turmaAlunoAtividadeStore.naoIraRepor({
            ids,
            observacao: this.motivoCancelamento,
        });
        this.onCancel();
    }
};
__decorate([
    Prop({ type: Array, default: () => [] })
], PedagogicoMarcarReposicaoComponent.prototype, "propItems", void 0);
PedagogicoMarcarReposicaoComponent = __decorate([
    Component
], PedagogicoMarcarReposicaoComponent);
export default PedagogicoMarcarReposicaoComponent;
