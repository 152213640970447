var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"mt-0 pt-0",attrs:{"fluid":""}},[_c('v-card',{staticClass:"ma-1 pa-3 rounded-xl mb-3"},[_c('v-card-title',{attrs:{"primary-title":""}},[_c('div',{staticClass:"headline primary--text"},[_vm._v(_vm._s(_vm.getNomeAulaExtraSingular()))])]),_c('show-popup',{ref:"showAlunoReforco",attrs:{"propTitle":"Pedagógico"}},[(_vm.selectedAluno)?_c('pedagogico-aluno-component',{key:_vm.selectedAluno.aluno_id,attrs:{"propAlunoId":parseInt(_vm.selectedAluno.aluno_id),"propModoCobranca":true},on:{"on-cancel":function($event){return _vm.atualizarDados()}}}):_vm._e(),(_vm.selectedAluno)?_c('ocorrencia-component',{key:_vm.selectedAluno.id,attrs:{"propEntidadeId":parseInt(_vm.selectedAluno.aluno_id),"propDepartamentoOcorrenciaId":[3],"propEntidadeTipo":"aluno","propShowAll":true}}):_vm._e()],1),_c('v-row',_vm._l((_vm.diasDaSemana()),function(dia){return _c('v-col',{key:dia},[_c('clickable-card',{attrs:{"prop-single-selection":false,"prop-card-title":dia,"prop-card-text":((_vm.qtdAlunoPorDiaSemana[dia]) + " alunos"),"prop-loading":_vm.loading},model:{value:(_vm.propertyToShowDetails),callback:function ($$v) {_vm.propertyToShowDetails=$$v},expression:"propertyToShowDetails"}})],1)}),1),_c('v-row',[_c('v-col',[_c('v-card',[_c('v-card-text',[_c('v-text-field',{attrs:{"label":"Buscar","single-line":"","append-icon":"mdi-magnify","hide-details":""},model:{value:(_vm.atribuidasVoceSearch),callback:function ($$v) {_vm.atribuidasVoceSearch=$$v},expression:"atribuidasVoceSearch"}}),_c('v-data-table',{attrs:{"headers":_vm.header,"items":_vm.itemsFormatted,"item-key":"id","search":_vm.atribuidasVoceSearch,"footer-props":{
                itemsPerPageOptions: [-1],
              },"dense":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.created_at",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDateTime")(item.created_at))+" ")]}},{key:"item.data_hora",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDateTime")(item.data_hora))+" ")]}},{key:"item.aluno",fn:function(ref){
              var item = ref.item;
return [_c('entidade-link-component',{attrs:{"propEntidadeId":item.aluno_id,"propEntidadeNome":item.aluno,"propEntidadeTipo":"aluno"}})]}},{key:"item.professor",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(item.professor_nome)+" ")]}},{key:"item.professor_turma",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(item.professor_turma_nome)+" ")]}},{key:"item.actions",fn:function(ref){
              var item = ref.item;
return [_c('v-icon',{on:{"click":function($event){return _vm.showAluno(item)}}},[_vm._v("mdi-eye")])]}},{key:"item.descricao",fn:function(ref){
              var value = ref.value;
return [_c('text-expandable-component',[_vm._v(" "+_vm._s(value)+" ")])]}},{key:"item.aluno_contatos",fn:function(ref){
              var item = ref.item;
return [_c('text-expandable-component',_vm._l((item.aluno_contatos),function(contato,idx){return _c('div',{key:idx},[(contato.observation)?_c('span',[_vm._v(_vm._s(contato.observation)+" -")]):_vm._e(),_vm._v(" "+_vm._s(contato.phone)),_c('br')])}),0)]}},{key:"item.status",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.aulaExtraStatusOptions(item.status))+" ")]}}],null,true)})],1)],1)],1)],1),_c('v-dialog',{attrs:{"width":"1200"},model:{value:(_vm.dialogMarcarReposicao),callback:function ($$v) {_vm.dialogMarcarReposicao=$$v},expression:"dialogMarcarReposicao"}},[(_vm.dialogMarcarReposicao)?_c('pedagogico-marcar-reposicao-component',{attrs:{"propItems":[
        {
          turma_id: _vm.turma_id,
          aluno_id: _vm.aluno_id,
          turma_aluno_id: _vm.turma_aluno_id,
          turma_aluno_atividade_id: _vm.turma_aluno_atividade_id,
          atividade_id: _vm.atividade_id,
        } ],"prop-type-tela":"AulaExtra"}}):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }