import { __decorate } from "tslib";
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import _ from 'lodash';
import { mainStore, reposicaoStore, turmaAlunoAtividadeStore, turmaStore, salaStore, } from '@/utils/store-accessor';
import ClickableCard from '@/components/ClickableCard.vue';
import { newReposicao } from '@/interfaces/reposicao';
import DateFieldComponent from '@/components/DateFieldComponent.vue';
let PedagogicoMarcarReposicaoComponent = class PedagogicoMarcarReposicaoComponent extends Vue {
    constructor() {
        super(...arguments);
        this.headers = [
            { text: 'Atividade', value: 'atividade_descricao' },
            { text: 'Executada em', value: 'atividade_data_executada' },
            { text: 'Presença', value: 'presenca' },
        ];
        this.reporOutroDiaSelected = [];
        this.loading = false;
        // public observacao = '';
        this.lastHorarioSelected = { selected: false };
        this.turmaSelected = [];
        this.reposicaoSelected = [];
        this.professorSelected = {};
        this.historicoReposicaoAluno = [];
        this.reposicoesNovaData = [];
        this.horarioManha = {};
        this.horarioTarde = {};
        this.horarioNoite = {};
        this.turma = null;
        this.permitirAposProximaAula = [];
        this.nova_reposicao_data = null;
        this.incluirFinanceiro = false;
        this.marcarJustificada = false;
        this.aulaOnlineUrl = '';
        this.entityObject = {};
        this.isloadingHorarios = false;
        // public headersHistoricoReposicaoAluno = [
        //   { text: 'Data', value: 'reposicao.data_hora' },
        //   { text: 'Unidade', value: 'reposicao.atividade.descricao' },
        //   { text: 'Observação', value: 'reposicao.observacao' },
        //   { text: 'Status', value: 'reposicao.status' },
        // ];
        this.reporNovaDataHeaders = [
            { text: 'Professor', value: 'professor_nome' },
            { text: 'Sala', value: 'sala_id' },
            { text: '', value: 'actions' },
        ];
    }
    onChangeTurmaSelected(val) {
        if (val.length > 0) {
            this.reposicaoSelected = [];
            this.professorSelected = {};
        }
    }
    onChangeReposicaoSelected(val) {
        if (val.length > 0) {
            this.turmaSelected = [];
            this.professorSelected = {};
        }
    }
    onChangeProfessorSelected(val) {
        if (Object.keys(val).length > 0) {
            this.turmaSelected = [];
            this.reposicaoSelected = [];
        }
    }
    salasFiltradas(ids) {
        return _.filter(this.salasOptions, (item) => {
            return ids.includes(item.id);
        });
    }
    get salasOptions() {
        return salaStore.salas;
    }
    get horariosDisponiveis() {
        if (this.reporOutroDiaSelected.length > 0) {
            // @ts-ignore
            return _.find(this.propOpcoesReposicao.nova_data, 
            // @ts-ignore
            (item) => item.data === this.BRToDate(this.reporOutroDiaSelected[0]));
        }
    }
    get getCurrentUser() {
        return mainStore.userProfile;
    }
    async consultaHorariosDisponiveis() {
        if (!this.nova_reposicao_data) {
            return;
        }
        this.isloadingHorarios = true;
        this.reposicoesNovaData = await turmaAlunoAtividadeStore.getHorariosDisponiveis({
            data: this.nova_reposicao_data,
            idioma: this.propItems[0].idioma || 'Inglês',
        });
        this.horarioManha = {};
        this.horarioTarde = {};
        this.horarioNoite = {};
        _.map(this.reposicoesNovaData, (item, i, v) => {
            if (i < 720) {
                if (!this.horarioManha[i]) {
                    this.horarioManha[i] = [];
                }
                this.horarioManha[i].push(...item);
            }
            else if (i < 1080) {
                if (!this.horarioTarde[i]) {
                    this.horarioTarde[i] = [];
                }
                this.horarioTarde[i].push(...item);
            }
            else if (i >= 1080) {
                if (!this.horarioNoite[i]) {
                    this.horarioNoite[i] = [];
                }
                this.horarioNoite[i].push(...item);
            }
        });
        this.isloadingHorarios = false;
    }
    onCancel() {
        this.$emit('on-cancel');
    }
    handleClick(row) {
        this.professorSelected = row;
    }
    async marcarReposicaoOuAulaExtra() {
        this.loading = true;
        if (
        // @ts-ignore
        Array.isArray(this.professorSelected.sala_id) &&
            // @ts-ignore
            !this.professorSelected.sala_id_selected) {
            mainStore.addErrorToasted({
                // @ts-ignore
                toasted: this.$toasted,
                errors: ['É necessário escolher uma sala para a reposição'],
            });
            this.loading = false;
            return;
        }
        if (this.turmaSelected.length > 0) {
            // em outra turma
            const obj = {
                id: this.propItems[0].turma_aluno_atividade_id,
                reposicao_turma_atividade_id: 
                // @ts-ignore
                this.turmaSelected[1].turma_atividade_id,
                reposicao_agendada: true,
                atividade_presenca: null,
                reposicao_concluida: null,
                reposicao_nao_repor: null,
            };
            turmaAlunoAtividadeStore.turmaAlunoAtividadeUpdate(obj);
            this.onCancel();
            return;
        }
        if (this.reposicaoSelected.length > 0) {
            // em outra reposicao
            const obj = {
                // @ts-ignore
                reposicao_id: this.reposicaoSelected[1].reposicao_id,
                aluno_id: this.propItems[0].aluno_id,
                turma_aluno_atividade_id: this.propItems[0].turma_aluno_atividade_id,
                incluir_financeiro: this.incluirFinanceiro,
                marcar_justificada: this.marcarJustificada,
            };
            reposicaoStore.createReposicoesAlunos([obj]);
            const obj2 = {
                id: this.propItems[0].turma_aluno_atividade_id,
                reposicao_agendada: true,
                atividade_presenca: null,
                reposicao_concluida: null,
                reposicao_nao_repor: null,
            };
            turmaAlunoAtividadeStore.turmaAlunoAtividadeUpdate(obj2);
            this.onCancel();
            return;
        }
        if (Object.keys(this.professorSelected).length > 0) {
            // nova reposicao
            const reposicao = newReposicao();
            // @ts-ignore
            reposicao.professor_id = this.professorSelected.professor_id;
            reposicao.company_id = mainStore.userProfile.company.id;
            // @ts-ignore
            const data_hora = `${this.professorSelected.dia}T${this.professorSelected.minuto_inicio_formatado}`;
            const horario_brasilia = new Date(data_hora);
            // @ts-ignore
            reposicao.data_hora = horario_brasilia;
            // @ts-ignore
            reposicao.sala_id = Array.isArray(this.professorSelected.sala_id)
                ? // @ts-ignore
                    this.professorSelected.sala_id_selected
                : // @ts-ignore
                    this.professorSelected.sala_id;
            reposicao.atividade_id = this.propItems[0].atividade_id;
            if (this.propTypeTela === 'Reposicao') {
                // reposicao.observacao = this.observacao;
                reposicao.status = 0;
                reposicao.valor = 0;
                if (this.getCurrentUser.company.aula_online_allow) {
                    reposicao.aula_online_url = this.aulaOnlineUrl;
                }
                const reposicao_id = await reposicaoStore.createReposicao(reposicao);
                const reposicaoAluno = {
                    aluno_id: this.propItems[0].aluno_id,
                    reposicao_id,
                    turma_id: this.propItems[0].turma_id,
                    turma_aluno_id: this.propItems[0].turma_aluno_id,
                    turma_aluno_atividade_id: this.propItems[0].turma_aluno_atividade_id,
                    status: 0,
                    valor: null,
                    incluir_financeiro: this.incluirFinanceiro,
                    marcar_justificada: this.marcarJustificada,
                };
                reposicaoStore.createReposicoesAlunos([reposicaoAluno]);
            }
            else if (this.propTypeTela === 'AulaExtra') {
                const reposicaoUpdate = {
                    id: this.propItems[0].reposicao_id,
                    atividade_id: this.propItems[0].atividade_id,
                    company_id: mainStore.userProfile.company.id,
                    data_hora,
                    professor_id: reposicao.professor_id,
                    sala_id: reposicao.sala_id,
                    status: 4,
                    tipo: 1,
                    aprovada: false,
                    aula_online_url: this.aulaOnlineUrl
                };
                const reposicao_id = await reposicaoStore.updateReposicao(reposicaoUpdate);
                // const reposicaoAluno = {
                //   aluno_id: this.propItems[0].aluno_id,
                //   reposicao_id: reposicao_id,
                //   turma_id: this.propItems[0].turma_id,
                //   turma_aluno_id: this.propItems[0].turma_aluno_id,
                //   turma_aluno_atividade_id: this.propItems[0].turma_aluno_atividade_id,
                //   valor: 0,
                // };
                // reposicaoStore.createReposicoesAlunos([reposicaoAluno]);
            }
            this.onCancel();
            return;
        }
    }
    onChangeSelectedHorario(item) {
        if (!this.lastHorarioSelected.selected) {
            this.lastHorarioSelected = item;
            return;
        }
        if (this.lastHorarioSelected.selected) {
            this.lastHorarioSelected.selected = false;
            this.lastHorarioSelected = item;
        }
    }
    async mounted() {
        await salaStore.getSalas();
        this.turma = await turmaStore.getTurma(this.propItems[0].turma_id);
        // this.historicoReposicaoAluno = await reposicaoStore.getReposicoesByAluno(
        //   this.propItems[0].aluno_id
        // );
    }
};
__decorate([
    Prop({ type: Array, default: () => [] })
], PedagogicoMarcarReposicaoComponent.prototype, "propItems", void 0);
__decorate([
    Prop({ type: Object })
], PedagogicoMarcarReposicaoComponent.prototype, "propOpcoesReposicao", void 0);
__decorate([
    Prop({ type: String, required: true, default: 'AulaExtra' || 'Reposicao' })
], PedagogicoMarcarReposicaoComponent.prototype, "propTypeTela", void 0);
__decorate([
    Watch('turmaSelected')
], PedagogicoMarcarReposicaoComponent.prototype, "onChangeTurmaSelected", null);
__decorate([
    Watch('reposicaoSelected')
], PedagogicoMarcarReposicaoComponent.prototype, "onChangeReposicaoSelected", null);
__decorate([
    Watch('professorSelected')
], PedagogicoMarcarReposicaoComponent.prototype, "onChangeProfessorSelected", null);
PedagogicoMarcarReposicaoComponent = __decorate([
    Component({
        components: {
            ClickableCard,
            DateFieldComponent,
        },
    })
], PedagogicoMarcarReposicaoComponent);
export default PedagogicoMarcarReposicaoComponent;
